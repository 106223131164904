const stateOptions = [
    {label: "启用", value: true},
    {label: "禁用", value: false},
];
const typeOptions = [
    {label: "内部", value: 1},
    {label: "外部", value: 2},
];
const authStatusOptions = [
    {label: "认证中", value: 1},
    {label: "认证通过", value: 2},
    {label: "认证失败", value: 3},
];
const authenticationTypeOptions = [
    {label: "个人认证", value: 'A01'},
    {label: "企业认证", value: 'A02'},
];
export {
    stateOptions,
    typeOptions,
    authStatusOptions,
    authenticationTypeOptions,
}
