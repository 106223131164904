import validator from './validate'

/*平台用户查询*/
let platSearch = {
    userNick: [
        {type: 'number', message: '年龄必须为数字值', trigger: 'blur'}
    ],
    organize: [
        {required: true, message: '产品名称不能为空', trigger: 'blur'},
    ],
}

let groupAddRules = { //集团新增
    code:[
        {required: true, message: '集团编码不能为空', trigger: 'blur'},
        {max:20, message: '编码最长为20字符', trigger: 'change'},
        {pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{1,20}$/, message: '编码需要是字母加数字组成', trigger: 'blur'},
        {pattern: /^[a-zA-Z][a-zA-Z0-9]*$/, message: '编码只能是字母（开头）、数字组成', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '编码内不能包含空格', trigger: ['blur', 'change']},
    ],
    contactNumber: [
        {required: true, message: '联系电话不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '联系电话内不能包含空格', trigger: ['blur', 'change']},
        {max:20, message: '联系电话最长为20字符', trigger: 'change'}
    ],
    name: [
        {required: true, message: '集团名称不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '集团名称内不能包含空格', trigger: ['blur', 'change']},
        {max: 50, message: '长度最长不超过50个字符', trigger: ['blur', 'change']}
    ],
    address: [
        {required: true, message: '地址不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '地址内不能包含空格', trigger: ['blur', 'change']},
        {max: 100, message: '长度最长不超过100个字符', trigger: ['blur', 'change']}
    ],
    contactPerson: [
        {required: true, message: '联系人不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '联系人内不能包含空格', trigger: ['blur', 'change']},
        {max: 10, message: '长度最长不超过10个字符', trigger: ['blur', 'change']}
    ],
    enabled: [
        {required: true, message: '状态不能为空', trigger: ['blur', 'change']},
    ],
    type: [
        {required: true, message: '集团性质不能为空', trigger: ['blur', 'change']},
    ],

}
let organizeAddRules = { //组织新增
    code:[
        {required: true, message: '组织编码不能为空', trigger: 'blur'},
        {max:20, message: '编码最长为20字符', trigger: 'change'},
        {pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{1,20}$/, message: '编码需要是字母加数字组成', trigger: 'blur'},
        {pattern: /^[a-zA-Z][a-zA-Z0-9]*$/, message: '编码只能是字母（开头）、数字组成', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '编码内不能包含空格', trigger: ['blur', 'change']},
    ],
    contactNumber: [
        {required: true, message: '联系电话不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '联系电话内不能包含空格', trigger: ['blur', 'change']},
        {max:20, message: '联系电话最长为20字符', trigger: 'change'},
    ],
    name: [
        {required: true, message: '组织名称不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '组织名称内不能包含空格', trigger: ['blur', 'change']},
        {max: 50, message: '长度最长不超过50个字符', trigger: ['blur', 'change']}
    ],
    address: [
        {required: true, message: '地址不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '地址内不能包含空格', trigger: ['blur', 'change']},
        {max: 100, message: '长度最长不超过100个字符', trigger: ['blur', 'change']}
    ],
    contactPerson: [
        {required: true, message: '联系人不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '联系人内不能包含空格', trigger: ['blur', 'change']},
        {max: 10, message: '长度最长不超过10个字符', trigger: ['blur', 'change']}
    ],
    enabled: [
        {required: true, message: '状态不能为空', trigger: ['blur', 'change']},
    ],
    groupId: [
        {required: true, message: '所属集团不能为空', trigger: ['blur', 'change']},
    ],
}

let factoryAddRules = { //厂区新增
    contactNumber: [
        {required: true, message: '联系电话不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '联系电话内不能包含空格', trigger: ['blur', 'change']},
        {max:20, message: '联系电话最长为20字符', trigger: 'change'}

    ],
    name: [
        {required: true, message: '厂区名称不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '厂区名称内不能包含空格', trigger: ['blur', 'change']},
        {max: 50, message: '长度最长不超过50个字符', trigger: ['blur', 'change']}
    ],
    address: [
        {required: true, message: '地址不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '地址内不能包含空格', trigger: ['blur', 'change']},
        {max: 100, message: '长度最长不超过100个字符', trigger: ['blur', 'change']}
    ],
    contactPerson: [
        {required: true, message: '联系人不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '联系人内不能包含空格', trigger: ['blur', 'change']},
        {max: 10, message: '长度最长不超过10个字符', trigger: ['blur', 'change']}
    ],
    enabled: [
        {required: true, message: '状态不能为空', trigger: ['blur', 'change']},
    ],
    orgId: [
        {required: true, message: '所属组织不能为空', trigger: ['blur', 'change']},
    ],
}

let departmentAddRules = { //部门新增
    code:[
        {required: true, message: '部门编码不能为空', trigger: 'blur'},
        {max:20, message: '编码最长为20字符', trigger: 'change'},
        {pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{1,20}$/, message: '编码需要是字母加数字组成', trigger: 'blur'},
        {pattern: /^[a-zA-Z][a-zA-Z0-9]*$/, message: '编码只能是字母（开头）、数字组成', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '编码内不能包含空格', trigger: ['blur', 'change']},
    ],
    name: [
        {required: true, message: '部门名称不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '部门名称内不能包含空格', trigger: ['blur', 'change']},
        {max: 20, message: '长度最长不超过20个字符', trigger: ['blur', 'change']}
    ],
    enabled: [
        {required: true, message: '状态不能为空', trigger: ['blur', 'change']},
    ],
    factoryId: [
        {required: true, message: '所属厂区不能为空', trigger: 'blur'},
    ],
    orgId: [
        {required: true, message: '所属组织不能为空', trigger: 'blur'},
    ],
    note: [
        {max: 100, message: '长度最长不超过100个字符', trigger: ['blur', 'change']}
    ],
}
let projectAddRules = { //项目新增
    code: [
        {required: true, message: '项目编号不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '项目编号内不能包含空格', trigger: ['blur', 'change']},
    ],
    clientId: [
        {required: true, message: 'clientId不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: 'clientId内不能包含空格', trigger: ['blur', 'change']},
        {pattern: /^[a-zA-Z][a-zA-Z0-9_-]*$/, message: '输入内容包含非法字符', trigger: 'blur'},
        {max: 100, message: '长度最长不超过100个字符', trigger: ['blur']}

    ],
    name: [
        {required: true, message: '项目名称不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '项目名称内不能包含空格', trigger: ['blur', 'change']},
        {max: 20, message: '长度最长不超过20个字符', trigger: ['blur', 'change']}
    ],
    adminUser: [
        {required: true, message: '超管姓名不能为空', trigger: 'blur'},
    ],
    description: [
        {max: 100, message: '长度最长不超过100个字符', trigger: ['blur', 'change']}
    ],
}
let productRules = {//产品管理
    productName: [
        {required: true, message: '产品名称不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '产品名称内不能包含空格', trigger: ['blur', 'change']},
        {min: 1, max: 12, message: '长度在12位以内', trigger: 'blur'}
    ],

    percents: [
        {required: true, message: '年利率不能为空', trigger: 'blur'},
        {validator: validator.validPrice, trigger: 'blur'},
    ],
    newpercents: [
        {validator: validator.validnPrice, trigger: 'blur'},
    ],
    notice: [
        {required: true, message: '产品说明不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '产品说明内不能包含空格', trigger: ['blur', 'change']},
    ],
}

let userRules = { //用户新增
    employeeNum: [
        {required: true, message: '工号不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '工号内不能包含空格', trigger: ['blur', 'change']},
        {max: 20, message: '长度最长不超过20个字符', trigger: ['blur', 'change']}
    ],
    username: [
        {required: true, message: '工号不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '工号内不能包含空格', trigger: ['blur', 'change']},
        {max: 20, message: '长度最长不超过20个字符', trigger: ['blur', 'change']}
    ],
    nickname: [
        {required: true, message: '真实姓名不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '真实姓名内不能包含空格', trigger: ['blur', 'change']},
        {max: 10, message: '长度最长不超过10个字符', trigger: ['blur', 'change']}
    ],
    telephone: [
        {required: true, message: '手机号不能为空', trigger: 'blur'},
        {min:11,max: 11, message: '手机号应为11个字符', trigger: ['blur', 'change']},
        {pattern: /^[0-9]*$/, message: '手机号需为数字', trigger: 'blur'},
        {pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '手机号格式不正确', trigger: 'blur'}
    ],
    password: [
        {required: true, message: '密码不能为空', trigger: 'blur'},
        {max: 20, message: '长度最长不超过20个字符', trigger: ['blur', 'change']},
        {pattern: /^[^\s]*$/, message: '密码内不能包含空格', trigger: ['blur', 'change']},
    ],
    enabled: [
        {required: true, message: '状态不能为空', trigger: ['blur', 'change']},
    ],
    userOrgList:[
        {type: 'array',required: true, message: '组织不能为空', trigger: ['blur', 'change']},
    ],
    description: [
        {max: 100, message: '长度最长不超过100个字符', trigger: ['blur', 'change']}
    ],
}

let userEditRules = { //用户新增
    employeeNum: [
        {required: true, message: '工号不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '工号内不能包含空格', trigger: ['blur', 'change']},
        {max: 20, message: '长度最长不超过20个字符', trigger: ['blur', 'change']}
    ],
    username: [
        {required: true, message: '工号不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '工号内不能包含空格', trigger: ['blur', 'change']},
        {max: 20, message: '长度最长不超过20个字符', trigger: ['blur', 'change']}
    ],
    nickname: [
        {required: true, message: '真实姓名不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '真实姓名内不能包含空格', trigger: ['blur', 'change']},
        {max: 10, message: '长度最长不超过10个字符', trigger: ['blur', 'change']}
    ],
    telephone: [
        {required: true, message: '手机号不能为空', trigger: 'blur'},
        {min:11,max: 11, message: '手机号应为11个字符', trigger: ['blur', 'change']},
        {pattern: /^[0-9]*$/, message: '手机号需为数字', trigger: 'blur'}
    ],
    password: [
        {max: 20, message: '长度最长不超过20个字符', trigger: ['blur', 'change']},
        {pattern: /^[^\s]*$/, message: '密码内不能包含空格', trigger: ['blur', 'change']},

    ],
    enabled: [
        {required: true, message: '状态不能为空', trigger: ['blur', 'change']},
    ],
    userOrgList:[
        {type: 'array',required: true, message: '组织不能为空', trigger: ['blur', 'change']},
    ],
    description: [
        {max: 100, message: '长度最长不超过100个字符', trigger: ['blur', 'change']}
    ],
}
let roleRules={
    appId: [
        {required: true, message: '项目名称不能为空', trigger: ['blur', 'change']},
        {pattern: /^[^\s]*$/, message: '项目名称内不能包含空格', trigger: ['blur', 'change']},
    ],
    code: [
        {required: true, message: '角色编码不能为空', trigger: ['blur', 'change']},
        {pattern: /^[^\s]*$/, message: '角色编码内不能包含空格', trigger: ['blur', 'change']},
        {max: 20, message: '长度最长不超过20个字符', trigger: ['blur', 'change']},
        {pattern: /^[a-zA-Z][a-zA-Z0-9_]*$/, message: '只能输入字母（开头）、数字、下划线', trigger: 'blur'}
    ],
    roleCode: [
        {required: true, message: '角色编码不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '角色编码内不能包含空格', trigger: ['blur', 'change']},
    ],
    enabled: [
        {required: true, message: '状态不能为空', trigger: ['blur', 'change']},
    ],
    name: [
        {required: true, message: '角色名称不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '角色名称内不能包含空格', trigger: ['blur', 'change']},
        {max: 20, message: '长度最长不超过20个字符', trigger: ['blur', 'change']}
    ],
    description: [
        {max: 100, message: '长度最长不超过100个字符', trigger: ['blur', 'change']}
    ],
}
let menuRules={
    appId: [
        {required: true, message: '项目名称不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '项目名称内不能包含空格', trigger: ['blur', 'change']},
    ],
    code: [
        {max: 20, message: '长度最长不超过20个字符', trigger: ['blur', 'change']},
        {pattern: /^[a-zA-Z][a-zA-Z0-9_]*$/, message: '只能输入字母（开头）、数字、下划线', trigger: 'blur'}
    ],
    path: [
        {required: true, message: '菜单地址不能为空', trigger: 'blur'},
        {max: 20, message: '长度最长不超过20个字符', trigger: ['blur', 'change']},
        {pattern: /^[a-zA-Z][a-zA-Z0-9_]*$/, message: '只能输入字母（开头）、数字、下划线', trigger: 'blur'}
    ],
    enabled: [
        {required: true, message: '状态不能为空', trigger: ['blur', 'change']},
    ],
    name: [
        {required: true, message: '菜单名称不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '菜单名称内不能包含空格', trigger: ['blur', 'change']},
        {max: 20, message: '长度最长不超过20个字符', trigger: ['blur', 'change']}
    ],
    sequence: [
        {required: true, message: '不能为空', trigger: 'blur'},
        {pattern: /^[0-9]*$/, message: '需为数字值', trigger: 'blur'},
        {validator: validator.validSequence, trigger: 'blur'},
    ],
    description: [
        {max: 100, message: '长度最长不超过100个字符', trigger: ['blur', 'change']}
    ],
}
let menuFunRules={
    name: [
        {required: true, message: '功能名称不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '功能名称内不能包含空格', trigger: ['blur', 'change']},
        {max: 20, message: '长度最长不超过20个字符', trigger: ['blur', 'change']}
    ],
    code: [
        {required: true, message: '功能编码不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '功能编码内不能包含空格', trigger: ['blur', 'change']},
        {max: 20, message: '长度最长不超过20个字符', trigger: ['blur', 'change']},
    ],
}
let authRules={
    appId: [
        {required: true, message: '项目名称不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '项目名称内不能包含空格', trigger: ['blur', 'change']},
    ],
    roleId: [
        {required: true, message: '角色不能为空', trigger: 'blur'},
        {pattern: /^[^\s]*$/, message: '角色内不能包含空格', trigger: ['blur', 'change']},
    ],
}
let editPasswordRules={
    oldPsw: [
        {required: true, message: '密码不能为空', trigger: 'blur'},
        {max: 20, message: '长度最长不超过20个字符', trigger: ['blur', 'change']},
        {pattern: /^[^\s]*$/, message: '密码内不能包含空格', trigger: ['blur', 'change']},
    ],
    newPsw: [
        {required: true, message: '新密码不能为空', trigger: 'blur'},
        {max: 20, message: '长度最长不超过20个字符', trigger: ['blur', 'change']},
        {pattern: /^[^\s]*$/, message: '密码内不能包含空格', trigger: ['blur', 'change']},
    ],
    rePsw: [
        {required: true, message: '密码不能为空', trigger: 'blur'},
        {max: 20, message: '长度最长不超过20个字符', trigger: ['blur', 'change']},
        {pattern: /^[^\s]*$/, message: '密码内不能包含空格', trigger: ['blur', 'change']},
    ],
}
export {
    platSearch,
    productRules,
    userRules,
    groupAddRules,
    organizeAddRules,
    factoryAddRules,
    departmentAddRules,
    projectAddRules,
    roleRules,
    menuRules,
    authRules,
    menuFunRules,
    editPasswordRules,
    userEditRules
}
