<template>
    <div style="height: 100%;width: 100%;">
        <pubHeader class="pub_header" v-if="header"></pubHeader>
        <pubMenu @checkMenu="checkMenuState" v-if="menu"></pubMenu>
        <div :class="{pubContent:!this.$route.query.projectId,ml60:isCollapse,fullScreen:this.$route.query.projectId}">
<!--            <div class='default-padding' v-show='!$route.meta.noTitle'>{{parentTitle}} <span v-if="parentTitle&&title">/</span> {{title}}</div>-->
            <div :class="{'scroll-set':scrollget,'scroll-d':!scrollget}"> <slot v-wechat-title="title"></slot></div>  <!--插槽-->
        </div>
    </div>
</template>
<script>
    import pubHeader from '@components/pubHeader';
    import pubMenu from '@components/pubMenu';
    import { mapGetters } from 'vuex'
    export default {
        components: {
            pubHeader,
            pubMenu
        },
        data () {
            return {
                isCollapse: false,
                isProject:true
            }
        },
        computed:{
            ...mapGetters([
                'scrollget',
            ]),
            title() {
                return this.$route && this.$route.meta && this.$route.meta.title;
            },
            parentTitle(){
                return this.$route && this.$route.meta && this.$route.meta.parent;
            },header(){
                return this.$route && this.$route.meta && this.$route.meta.header;
            },menu(){
                return this.$route && this.$route.meta && this.$route.meta.menu;
            },
        },

        methods: {
            checkMenuState (state) {
                this.isCollapse = state
            }
        },
        // watch:{
        //     $route(to){
        //         if (to.token){
        //             let tokens={
        //                 access_token:to.token
        //             }
        //             localStorage.setItem('accessToken',JSON.stringify(tokens))
        //         }
        //         console.log(to)
        //     }
        // },
    }
</script>
<style lang="scss">
    .scroll-set{
        height: calc(100% - 78px);
        overflow-y: scroll;
    }
    .scroll-d{
        height: calc(100% - 78px);
        overflow: hidden;
        display: flex;
        align-items: center;
    }
    .pubContent {
        overflow: hidden;
        width:calc(100% - 234px);
        height: 100%;
        margin-left: 222px;
        box-sizing: border-box;
        overflow-y: auto;
        background: #f6f7fb;
        position: relative;
        transition: all 0.3s ease-in;
    }
    .ml60 {
        margin-left: 60px;
        width:calc(100% - 72px);
    }
    .fullScreen{
        margin-left: 0;
        width: 100%;
    }
    .default-padding{
        padding: 0 30px;
        line-height: 50px;
        height: 50px;
        font-size: 14px;
        background-color:#fff;
        font-weight:400;
        color: rgba(0,0,0,0.45);
    }
  .pub_header{
    margin-bottom: 12px;
  }
</style>
